.c-intro {
	padding-top: 63px;
	color: $dark-grey;

	p {
		font-family: $alt-font;
		font-size: 36px;
		line-height: 46px;
	}
}

.c-intro--product {
	padding-top: 33px;
}

.l-wrapper--dark {
	.c-intro {
		color: $alt-brand-color;
	}
}

@media all and (max-width: $bp-large) {
	.c-intro--product {
		padding-top: 36px;
	}

	.c-intro {
		p {
			font-size: 24px;
			line-height: 36px;
		}
	}
}

@media all and (max-width: $bp-medium) {
	.c-intro {
		padding-top: 38px;
	}
}