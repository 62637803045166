.c-video {
	position: relative;
	width: 100%;
	padding-bottom: 49%;
}

.c-video__inner {
	position: absolute;
	left: $c-padding;
	right: $c-padding;
	top: 0;
	height: 100%;

	background-color: $brand-color;
	text-align: center;
	cursor: pointer;

	&:hover {
		small {
			color: $dark-grey;
		}
	}
}

.c-video__description {
	max-width: 511px;
	font-family: $alt-font;
	font-size: 36px;
	color: $dark-grey;
	line-height: 46px;

	small {
		display: inline-block;
		margin-top: 37px;
		font-family: $default-font-regular;
		font-size: 18px;
		color: #fff;
	}
}

@media all and (max-width: $bp-medium) {
	.c-video {
		padding-bottom: 50%;
	}

	.c-video__inner {
		left: 0;
		right: 0;
	}

	.c-video__description {
		max-width:  400px;
		font-size: 24px;
		line-height: 36px;
		// padding: 0 30px;

		small {
			margin-top: 25px;
			font-size: 16px;
		}
	}
}

.c-video__video-container {
	display: none;
	position: absolute;
	width: 100%;
	height: 100%;

	.video, iframe {
		width: 100%;
		height: 100%;
	}
}

@media all and (max-width: 480px) {
	.c-video {
		padding-bottom: 90%;
	}

	.c-video__description {
		max-width: 340px;
	}
}

@media all and (max-width: 380px) {
	.c-video {
		padding-bottom: 100%;
	}

	.c-video__description {
		max-width: 290px;
	}
}