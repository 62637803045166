.c-spec {
	width: 100%;
	padding: 10px 6px 8px;

	border-bottom: solid 1px $dark-grey;
}

.c-spec__title, .c-spec__description {
	font-size: 15px;
	line-height: 19px;
	color: $dark-grey;
	letter-spacing: 0.1px;
}

.c-spec__title {
	float: left;
	width: 182px;
	padding-right: 8px;
	font-family: $default-font-bold;
}

.c-spec__description {
	display: block;
	margin-left: 182px;
	font-family: $default-font-regular;
}

@media all and (max-width: 1200px) {
	.c-spec__title {
		width: 118px;
	}

	.c-spec__description {
		margin-left: 132px;
	}
}

@media all and (max-width: $bp-small) {
	.c-spec__title {
		width: 86px;
	}

	.c-spec__description {
		margin-left: 100px;
	}
}