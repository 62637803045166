.l-wrapper__outer {
	position: relative;
	width: 100%;
}

.l-wrapper {
	position: relative;
	margin: 0 auto 102px;
	max-width: 1440px;
	padding: 0 172px;
}

.l-wrapper--no-col {
	padding: 0 180px;
}

.l-wrapper--neutral {
	background-color: $light-grey;
}

.l-wrapper--dark {
	background-color: $dark-grey;
	color: $light-grey;
	margin-bottom: 0;
	padding-bottom: 172px;
}

.l-wrapper--yellow {
	background-color: $alt-brand-color;
	color: $dark-grey;
	margin-bottom: 0;
	padding-bottom: 172px;
}

.l-wrapper--dark, .l-wrapper--yellow {
	.l-wrapper {
		margin-bottom: 0;
	}
}

.l-wrapper--wide {
	max-width: 1920px;
}

.l-wrapper--top {
	z-index: 10;
}

.l-wrapper--no-padding {
	padding: 0;

	& > * {
		padding-left: 0;
		padding-right: 0; 
	}
}

.l-wrapper > * {
	padding-left: $c-padding;
	padding-right: $c-padding;
}

.l-wrapper--collapsed {
	position: absolute;
	z-index: 2;
}

.l-wrapper--no-margin {
	margin-bottom: 0;
}

.l-wrapper--popup {
	margin-bottom: 0;
	padding: 0 0 34px;
	border-bottom: 1px solid $dark-grey;
}

.l-wrapper--popup + .l-wrapper--popup {
	border-bottom: none;
}

.l-wrapper--popup {
	margin-bottom: 0;
	padding: 0 34px 34px;
	border-bottom: 1px solid $dark-grey;
}

.l-wrapper--popup + .l-wrapper--popup {
	border-bottom: none;
}

@media all and (max-width: $bp-large) {
	.l-wrapper {
		padding-left: 36px;
		padding-right: 36px;
	}

	.l-wrapper--no-col {
		padding-left: 44px;
		padding-right: 44px;
	}
}

@media all and (max-width: $bp-medium) {
	.l-wrapper {
		margin-bottom: 91px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.l-wrapper--dark, .l-wrapper--yellow {
		margin-bottom: 0;
		padding-bottom: 91px;

		.l-wrapper {
			margin-bottom: 0;
		}
	}

	.l-wrapper--no-col {
		padding-left: 18px;
		padding-right: 18px;
	}

	.l-wrapper--mobile-no-padding {
		padding: 0;

		& > * {
			padding-left: 0;
			padding-right: 0; 
		}
	}

	.l-wrapper--popup {
		margin-bottom: 0;
		padding-left: 6px;
		padding-right: 6px;
	}
}

.l-wrapper--no-margin {
	margin-bottom: 0;
}

.l-wrapper--no-padding {
	padding: 0;

	& > * {
		padding-left: 0;
		padding-right: 0; 
	}
}