.l-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: $menu-height-desktop;
	background-color: #fff;
	z-index: 9;
	border-bottom: 1px solid #DDDDDD;
}

@media all and (max-width: $bp-medium) {
	.l-header {
		// padding-top: 9px;
		height: $menu-height-mobile;
		background-color: transparent;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			height: 51px;
			background-color: #fff;
		}
	}

	.l-header--open {
		height: 100%;
	}

	.l-header__inner {
		width: 100%;
		height: 100%;
		padding-top: 9px;
		// margin-top: -14px;
		transform: translate3d($bp-medium,0,0);
		transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
		background-color: $light-grey;
	}

	.open {
		.l-header__inner {
			transform: translate3d(0,0,0) !important;
		}
	}
}