.c-tile {
	position: relative;
	display: block;
	padding-bottom: 28px;
	margin-bottom: 8px;
	// opacity: 0;
	transition: opacity 0.3s ease;

	&:hover {
		background-color: $light-grey;
	}

	&.is-loaded {
		opacity: 1;
	}
}

.c-tile--category {
	text-align: center;

	&:hover {
		background-color: transparent;
	}
}

.c-tile__inner {
	position: relative;
	width: 100%;
	padding-bottom: 60.5%;
}

.c-tile__heading {
	position: absolute;
	left: 0;
	top: 50%;
	width: 100%;
	transform: translateY(-50%);

	font-family: $default-font-bold;
	font-size: 18px;
	letter-spacing: 0.7px;
}

.c-tile__img {
	position: absolute;
	width: 100%;
	height: 100%;

	min-height: 200px;

	&.lazyloading, &.lazyloaded {
		min-height: auto;
	}
}

.c-tile__caption {
	position: absolute;
	bottom: -15px;
	left: 0;
	width: 100%;
	height: 20px;
	font-family: $default-font-regular;
	font-size: 15px;
	letter-spacing: 0.5px;
	text-align: center;
	text-transform: uppercase;
	color: $dark-grey;
}