.c-menu-item {
	display: inline;

	font-family: $default-font-bold;
	font-size: 15px;
	letter-spacing: 0.5px;
	color: $dark-grey;

	a {
		display: inline-block;
		padding: 16px 6px 6px;
	}

	a:hover {
		color: $mid-grey;
	}
}

li.c-menu-item:last-child {
	margin-right: 3px;
}

li.c-menu-item--concat:last-child {
	margin-right: 0;
}

.c-menu-item--concat {
	position: relative;
	font-family: $default-font-regular;

	a {
		padding-left: 16px;
		padding-right: 16px;
	}

	&:after {
		content: '>';
		position: absolute;
		right: -8px;
		top: -2px;
	}
}

li.c-menu-item--concat:last-child:after {
	content: '';
}

@media all and (max-width: $bp-medium) {
	.c-menu-item {
		display: none;
	}

	.open {
		.c-menu-item {
			display: block;

			font-family: $alt-font;
			font-size: 33px;
			letter-spacing: 1.5px;
			text-transform: none;
			border-bottom: 1px solid $mid-grey;

			a {
				padding: 14px 0 13px;
				width: 100%;
			}
		}
	}
}