.c-filter {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 8;

	transition: transform 0.3s cubic-bezier(0.355, 0.160, 0.355, 1.000);
	transform: translate3d(0, 0, 0);
	text-align: center;
	background-color: $dark-grey;

	ul {
		padding: 0 80px 16px;
	}
}

.c-filter__heading {
	position: absolute;
	left: 16px;
	top: 0;
	height: 100%;

	font-family: $default-font-bold;
	font-size: 15px;
	letter-spacing: 0.2px;
	color: $light-grey;
}

.c-filter__item {
	display: inline-block;
	position: relative;

	color: $light-grey;
}

.c-filter__link {
	display: inline-block;
	padding: 16px 7px 0;
	font-family: $default-font-bold;
	font-size: 15px;
	letter-spacing: 0.2px;
	color: inherit;
	cursor: pointer;
	user-select: none;

	&:before {
		content:'';
		display: inline-block;
		width: 11px;
		height: 11px;
		margin-right: 6px;
		border-radius: 50%;
		background-color: #535353;
	}

	&:hover:before {
		background-color: $brand-color;
	}
}

.c-filter__link--active {
	&:before {
		background-color: $brand-color;
	}
}

.c-filter__toggle {
	display: none;
	padding: 8px 0 13px;

	font-family: $default-font-regular;
	font-size: 15px;
	letter-spacing: 0.2px;
	color: $light-grey;

	&:hover {
		color: $light-grey;
	}
}

@media all and (max-width: $bp-medium) {
	.c-filter__heading {
		display: none;
	}

	.c-filter__toggle {
		display: block;
	}

	.c-filter {
		padding-top: 15px;
		transform: translate3d(0,-48px,0);

		ul {
			padding-top: 9px;
			padding-left: 8px;
			padding-right: 8px;
		}
	}

	.c-filter__item {
		width: 50%;
		margin-right: -5px;
		text-align: left;
	}
}