.c-caption {
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	margin-top: 30px;

	text-align: left;
	font-family: $default-font-regular;
	font-size: 15px;
	letter-spacing: 0.15px;
	color: $dark-grey;
}

.c-caption--slide {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

@media all and (max-width: $bp-medium) {
	.c-caption {
		width: 25%;
	}
}