.l-slideshow {
	position: relative;
	width: 100%;
	min-height: 100vh;
	background-color: #F1F1F1;
}

.l-slideshow--light {
	background-color: transparent;
}

.l-slideshow--contained {
	max-width: 1096px;
	margin-left: auto;
	margin-right: auto;
}

.l-slideshow--landscape {
	padding-bottom: 50%;
	min-height: auto;
}

.l-slideshow--portrait {
	padding-bottom: 150%;
	min-height: auto;
}

.l-slideshow__inner {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.l-slideshow__nav {
	position: absolute;
	top: 50%;
	width: 100%;
	z-index: 10;
}

@media all and (orientation: portrait) {
	.l-slideshow--no-portrait {
		padding-bottom: 75%;
	}
}