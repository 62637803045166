.c-toggle {
	position: absolute;
	top: 8px;
	right: 0;

	cursor: pointer;
	font-family: $default-font-regular;
	font-size: 15px;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	color: $dark-grey;
	padding: 11px 11px 6px;
}

.c-toggle__icon {
	display: inline-block;
	position: relative;
	margin-right: 4px;
	height: 11px;
	width: 39px;
	border-top: 1px solid #868686;
	border-bottom: 1px solid #868686;

	&:after {
		content: '';
		display: block;
		margin-top: 4px;
		height: 1px;
		background-color: #868686;
	}
}

.open {
	.c-toggle__icon {
		border-top: none;
		border-bottom: none;
		width: 9px;

		&:before, &:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			height: 1px;
			margin-top: 5px;
			background-color: #000;
		}

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
	}
}

@media all and (min-width: $bp-medium) {
	.c-toggle {
		display: none;
	}
}