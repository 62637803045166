.c-pagination {
	position: absolute;
	right: 27px;
	bottom: 23px;

	font-family: $default-font-regular;
	font-size: 15px;
	letter-spacing: 0.15px;
	color: $dark-grey;
}

.c-pagination--below {
	right: 0;
	bottom: -47px;
}

@media all and (max-width: $bp-medium) {
	.c-pagination {
		bottom: 15px;
	}

	.c-pagination--below {
		right: 0;
		bottom: -47px;
	}
}