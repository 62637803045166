.l-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	cursor: pointer;
	z-index: 11;
	background-color: rgba(0,0,0,0.7);
}

.l-popup__inner {
	position: absolute;
	top: 79px;
	bottom: 79px;
	left: 0;
	right: 0;
	margin: 0 auto;
	width:90%;
	max-width: 1162px;
	background-color: #fff;
	overflow-y: scroll;
	cursor: default;
}

@media all and(max-width: $bp-medium) {
	.l-popup__inner {
		top: 30px;
		bottom: 30px;
		left: 12px;
		right: 12px;
	}
}