// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  @include bp-small(){
      @include define-breakpoint("bp-small");
  }  

  @include bp-medium(){
      @include define-breakpoint("bp-medium");
  }

  @include bp-large(){
      @include define-breakpoint("bp-large");
  }

   @include bp-xlarge(){
      @include define-breakpoint("bp-xlarge");
  }
}

figure {
  margin: 0;
}

blockquote {
  margin: 0;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
  box-sizing: inherit;
} 

/**
 * Basic styles for links
 */
a {
  color: inherit;
  text-decoration: inherit;

  @include on-event {
    color: inherit;
    text-decoration: inherit;
  }
}

::selection { color: $light-grey; background-color: $brand-color;}

/**
 * Normalize lists
 */
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

ol {
  margin: 0;
  padding: 0;
}

li {
	margin: 0;
	padding: 0;
}