.c-arrow {
	position: absolute;
	top: 50%;
	margin-top: -69px;
	z-index: 2;

	cursor: pointer;

	img {
		display: block;
	}
}


.c-arrow--next {
	right: 0;
	padding: 50px 72px 50px 20px;
}

.c-arrow--prev {
	left: 0;
	padding: 50px 20px 50px 72px;

	img {
		transform: rotate(180deg);
	}
}

.c-arrow--outside.c-arrow--next {
	right: -150px;
}

.c-arrow--outside.c-arrow--prev {
	left: -150px;
}

@media all and (max-width: $bp-large) {
	.c-arrow--next {
		padding-right: 45px;
	}

	.c-arrow--prev {
		padding-left: 45px;
	}

	.c-arrow--outside {
		margin-top: -28px;
		padding: 7px;
	}

	.c-arrow--outside.c-arrow--next {
		right: -17px;
		background-color: $light-grey;
	}

	.c-arrow--outside.c-arrow--prev {
		left: -17px;
		background-color: $light-grey;
	}
}

@media all and (max-width: $bp-medium) {
	.c-arrow {
		margin-top: -63px;

		img {
			width: 14px;
			height: 26px;
		}
	}

	.c-arrow--next {
		padding-right: 31px;
	}

	.c-arrow--prev {
		padding-left: 31px;
	}

	.c-arrow--outside {
		margin-top: -20px;
		padding: 7px;
	}
}