.c-content {
	padding-top: 59px;
	font-family: $default-font-regular;
	font-size: 19px;
	line-height: 28px;

	p + p {
		margin-top: 28px;
	}

	a:link {
		text-decoration: underline;
	}

	a:hover {
		text-decoration: none;
	}

	blockquote {
		margin-bottom: 20px;
		font-family: $alt-font;
		font-size: 36px;
		line-height: 46px;
		color: $alt-brand-color;
	}

	p + blockquote {
		padding-top: 15px;
	}

	figure {
		padding-top: 35px;
	}

	img {
		width: 100%;
		height: auto;
	}

	figcaption {
		margin-top: 12px;
		font-family: $default-font-bold;
		font-size: 15px;
		line-height: 20px;
	}

	strong {
		font-family: $default-font-bold;
		font-weight: 400;
		font-size: inherit;
	}

	h3 {
		margin-top: 41px;
		padding-bottom: 20px;
		font-family: $default-font-bold;
		font-size: 19px;
		line-height: 28px;
	}

	h3:first-child {
		margin-top: 0;
	}

	ol {
		padding-top: 28px;
		padding-left: 19px;
	}

	ul {
		padding-top: 28px;

		li {
			&:before {
				content:'–';
				margin-right: 8px;
			}
		}
	}

	.c-content__contact {
		padding-top: 0;

		li {
			position: relative;
			padding-left: 27px;

			&:before {
				content: '';
				margin: 0;
			}
		}
	}

	.c-content__icon {
		display: inline-block;
		position: absolute;
		left: 0;
		text-align: center;

		img {
			display: inline;
			width: auto;
		}
	}
}

.c-content--product {
	padding-top: 29px;
}

.l-wrapper--dark {
	.c-content {
		color: #f8f8f8;
	}
}

.l-wrapper--yellow {
	.c-content {
		color: $dark-grey;
	}
}

.l-popup {
	.c-content {
		padding-top: 45px;
	}
}

@media all and (max-width: $bp-large) {
	.c-content {
		font-size: 16px;

		blockquote {
			font-size: 24px;
			line-height: 36px;
		}
	}
}

@media all and (max-width: $bp-medium) {
	.c-content {
		padding-top: 38px;
		font-size: 16px;

		figure {
			padding-top: 18px;
		}

		h3 {
			padding-bottom: 9px;
		}
	}

	.c-content--product {
		padding-top: 27px;
	}
}