.l-col {
	float: left;
}

.l-col--1 	{ width: 100% / 12; }
.l-col--2 	{ width: 100% / 12 * 2; }
.l-col--3 	{ width: 100% / 12 * 3; }
.l-col--4 	{ width: 100% / 12 * 4; }
.l-col--5 	{ width: 100% / 12 * 5; }
.l-col--6 	{ width: 100% / 12 * 6; }
.l-col--7 	{ width: 100% / 12 * 7; }
.l-col--8 	{ width: 100% / 12 * 8; }
.l-col--9 	{ width: 100% / 12 * 9; }
.l-col--10 	{ width: 100% / 12 * 10; }
.l-col--11 	{ width: 100% / 12 * 11; }
.l-col--12 	{ width: 100%; }

.l-col--1 {
	width: 25%;
}

.l-col--mobile {
	display: none;
}

.l-col--indent {
	margin-left: 8.3%;
}

.l-col--no-float {
	float: none;
}

@media all and (max-width: $bp-medium) {
	.l-col { width: 100%; }
	.l-col--medium-6 { width: 50%; }

	.l-col--mobile {
		display: block;
	}

	.l-col--desktop {
		display: none;
	}

	.l-col--indent {
		margin-left: 0;
	}
}