// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

/// Create mixin for auto detecting breakpoint in Javascript
@mixin bp-small {
  @media only screen and (max-width: 320px) {
    @content;    
  }  
}

@mixin bp-medium {
  @media only screen and (min-width: 320px) {
    @content;    
  }  
}

@mixin bp-large {
  @media only screen and (min-width: 768px) {
    @content;    
  }  
}

@mixin bp-xlarge {
  @media only screen and (min-width: 1024px) {
    @content;    
  }  
}

@mixin define-breakpoint($name) {
    &:after {
      content: $name;
      display: none;
    }
}