.c-heading {
	display: table;
	margin: -34px 0 -5px -22px;
	padding: 18px 19px 0 24px;
	height: 33px;

	font-family: $default-font-regular;
	font-size: 16px;
	text-transform: uppercase;
	letter-spacing: 1.752px;
	background-color: #fff;
	color: $dark-grey;
}


.l-wrapper--dark {
	.c-heading {
		background-color: $dark-grey;
		color: $light-grey;
	}
}

.l-wrapper--yellow {
	.c-heading {
		background-color: $alt-brand-color;
		color: $dark-grey;
	}
}

.c-heading--alt {
	display: block;
	margin: 0;
	padding-top: 40px;
	padding-left: 8px;
}