.c-slide {
	position: relative;
	text-align: left;
	background-color: $light-grey;

	&:after {
		content: '';
		display: block;
		height: 0;
		width: 100%;
	}

	&:selected {
		border: none;
	}
}

.c-slide--flex:after {
	padding-bottom: 50%;
}

.c-slide--landscape:after {
	padding-bottom: 50%;
}

.c-slide--portrait:after {
	padding-bottom: 150%;
}

.c-slide__img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	display: block;
}

.c-slide__text {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	font-family: $alt-font;
	font-size: 36px;
	line-height: 46px;
	text-align: center;

	p {
		position: relative;
		top: 50%;
		padding: 0 120px; 
		transform: translateY(-50%);
	}
}

@media all and (max-width: $bp-large) {
	.c-slide__text {
		font-size: 24px;
		line-height: 36px;
	}
}

@media all and (max-width: $bp-medium) {
	.c-slide__text {
		font-size: 21px;
		line-height: 36px;

		p {
			padding: 0 54px;
		}
	}
}

@media all and (max-width: 500px) {
	.c-slide__text {
		font-size: 18px;
		line-height: 26px;

		p {
			padding: 0 16px;
		}
	}
}

@media all and (orientation:portrait) {
	.c-slide--flex:after {
		padding-bottom: 75%;
	}

	.c-slide--overflow {
		overflow-x: hidden;

		.c-slide__img {
			left: 50%;
			transform: translateX(-50%); 
			width: auto;
			height: 100%;
		}
	}
}