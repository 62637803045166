@font-face {
	font-family: 'Gothic720BT-RomanB';
	src: url('../fonts/gothic720-roman.eot');
	src: 
		url('../fonts/gothic720-roman.eot?#iefix') format('embedded-opentype'),
		url('../fonts/gothic720-roman.woff2') format('woff2'),
		url('../fonts/gothic720-roman.woff') format('woff'),
		url('../fonts/gothic720-roman.ttf') format('truetype');
}
  
@font-face {
	font-family: 'Gothic720BT-BoldB';
	src: url('../fonts/gothic720-bold.eot');
	src: 
		url('../fonts/gothic720-bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/gothic720-bold.woff2') format('woff2'),
		url('../fonts/gothic720-bold.woff') format('woff'),
		url('../fonts/gothic720-bold.ttf') format('truetype');
}