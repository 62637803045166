.c-footnote {
	display: none;
	position: absolute;
	bottom:19px;
	left: 17px;
	width: 50%;
	font-family: $default-font-regular;
	font-size: 15px;
	line-height: 24px;
	letter-spacing: 0.3px;
	color: $dark-grey;
}

.c-footnote--right {
	right: 21px;
	left: auto;
	text-align: right;
	bottom: 13px;
}

.c-footnote__icon {
	display: inline-block;
	padding: 6px 6px;
}

.open {
	.c-footnote {
		display: block;
	}
}

@media all and (min-width: $bp-medium) {
	.open {
		.c-footnote {
			display: none;
		}
	}
}