.c-reveal {
	display: none;
}

@media all and (max-width: $bp-medium) {
	.c-reveal {
		display: block;
		float: left;
		width: 100%;
		margin-top: 30px;
		padding-top: 23px;
		cursor: pointer;

		text-align: center;
		font-family: $default-font-regular;
		font-size: 15px;
		letter-spacing: 0.2px;

		&:hover {
			color: $mid-grey;
		}
	}
}