.l-main {
	max-width: 1920px;
	margin: $menu-height-desktop auto 0;
}

.l-main--padding {
	padding-top: 50px;
}

@media all and (max-width: $bp-medium) {
	.l-main {
		margin: $menu-height-mobile auto 0;
	}
}