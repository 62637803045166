.c-logo {
	display: block;
	position: absolute;
	left: 18px;
	bottom: 0;
	z-index: 4;

	&:hover {
		opacity: 0.65;
	}

	img {
		display: block;
	}
}

@media all and (max-width: $bp-medium) {
	.c-logo {
		left: 0;
		top: 10px;
		bottom: auto;
	}
}