.c-popup-title {
	display: block;
	padding: 17px 19px 11px 44px;
	margin-bottom: 5px;

	text-transform: uppercase;
	font-family: $default-font-regular;
	font-size: 16px;
	letter-spacing: 1.752px;
	color: $dark-grey;
	background-color: $alt-brand-color;
}

@media all and (max-width: $bp-medium) {
	.c-popup-title {
		padding-left: 13px;
	}
}