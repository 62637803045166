.l-menu {
	position: absolute;
	right: 14px;
	top: 0;
}

@media all and (max-width: $bp-medium) {
	.open {
		.l-menu {
			position: relative;
			top: 50%;
			right: auto;
			padding: 0 19px;
			transform: translateY(-50%);
			width: 100%;
		}
	}
}