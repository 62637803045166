.c-submenu {
	float: right;
	margin-top: 57px;
}

.c-submenu__item {
	padding: 4px 0;
	font-family: $default-font-regular;
	font-size: 15px;
	letter-spacing: 0.75px;
	color: $dark-grey;

	a:link {
		font-family: $default-font-bold;
	}

	a:hover {
		color: $mid-grey;
	}

	img {
		padding: 0 3px;

		&:hover {
			opacity: 0.4;
		}
	}
}

@media all and (max-width: $bp-medium) {
	.c-submenu {
		float: left;
		margin-top: 46px;
	}
}