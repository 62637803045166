.c-footer {
	padding-top: 94px;
	padding-bottom: 101px;
	text-align: center;
	background-color: $dark-grey;
	color: $light-grey;
	// z-index: 10;
}

.c-footer__heading {
	margin-bottom: 15px;
	font-family: $alt-font;
	font-size: 21px;
}

.c-footer__link {
	line-height: 28px;
	font-family: $default-font-regular;
	font-size: 16px;
	letter-spacing: 0.3px;

	&:hover {
		color: $brand-color;
	}
}

.c-footer__note {
	position: absolute;
	bottom: 19px;
	left: 23px;
	text-align: left;
	font-family: $default-font-regular;

	a:link, small {
		display: block;
		font-size: 16px;
		line-height: 24px;
		color: $mid-grey;
	}

	a:link {
		color: $light-grey;
	}

	a:hover {
		color: $brand-color;
	}
}

@media all and (max-width: $bp-medium) {
	.c-footer {
		padding-top: 31px;
		padding-bottom: 9px;
	}

	.c-footer__note {
		display: none;
	}

	.c-footer__link {
		display: inline-block;
		padding: 9px 0;
		font-family: $alt-font;
		font-size: 22px;
		line-height: 26px;
	}

	.c-footer__link--small {
		margin-top: 28px;
		font-family: $default-font-regular;
		font-size: 15px;
		letter-spacing: 0.5px;
		color: $mid-grey;
	}
}