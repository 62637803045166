.c-hero {
	position: relative;
	width: 100%;
	overflow: hidden;
	padding-bottom: 50%;
}

.c-hero__img {
	position: absolute;
	top: 0;
	left: 50%;
	width: auto;
	height: 100%;
	transform: translateX(-50%);
}

.c-hero__caption {
	margin: 0 auto;
	padding-top: 90px;
	width: 100%;
	max-width: 800px;
	text-align: center;

	// position: absolute;
	// top: 50%;
	// left: 0;
	// right: 0;
	// bottom:0;
	// padding: 0 50px;
	// transform: translateY(-50%);

	font-family: $alt-font;
	font-size: 37px;
	line-height: 49px;
}

.c-hero__caption--light {
	color: $light-grey;
}

.c-hero__caption--dark {
	color: $dark-grey;
} 

@media all and (max-width: $bp-medium) {
	.c-hero__caption {
		font-size: 24px;
		line-height: 35px;
	}
}

@media all and (max-width: 400px) {
	.c-hero__caption {
		font-size: 18px;
		line-height: 24px;
		padding: 54px 16px 0;
	}
}

@media all and (orientation: portrait) {
	.c-hero {
		padding-bottom: 75%;
	}
}